.MillGraderLogo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .logo {
    background-color: var(--color-base-white);
    width: 100px;
    height: 100px;
    border-radius: 50%;
    padding: 10px;
  }
  .logo-text {
    margin-top: 10px;
    font-family: var(--font-family-montserrat);
    .work {
      color: #dd8124ee;
      font-weight: 400;
    }
    .smart {
      color: var(--color-base-base-blue);
      font-weight: 400;
    }
  }
}
