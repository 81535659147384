.button {
  margin-top: 10px !important;
  border-radius: 20px;
  padding: 0.5rem;
  font-size: var(--font-size-s);
  color: var(--color-base-white);
  background-color: var(--color-base-base-blue);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
