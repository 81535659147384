.Login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  .login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .login-form {
      padding-top: 10px;
      width: 100%;
      color: var(--color-base-white);
      font-family: var(--font-family-roboto);
      align-self: flex-start;
    }
  }
  .error-msg {
    color: red;
    text-align: center;
  }
}
