.map-card{
    margin: 2rem;
}

.map-style{ 
    width: 100%;
    height: 600px;
    overflow: hidden;
}

// @media screen and (min-height : 500px) {
//     .map-style{ 
//         width: 100%;
//         height: 800px;
//     }
// }

.map-view-summary-map-section {
    height: 100%;
    min-height: 100%;
    display: -webkit-flex; /* Safari */
    display: flex;
    -webkit-align-items: center; /* Safari 7.0+ */
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    .map-container-view {
    width: 100%;
    height: 400px;
    max-height: 400px;
    margin-top: 5px;
    }
}

.map-marker-pin {
    background-image: url(../../assets/mill.svg);
    background-repeat: no-repeat;
    width: 40px !important;
    height: 40px !important;
  }
