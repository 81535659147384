.rounded-text-box {
  border-radius: 20px;
  padding: 0.5rem;
  padding-left: 1rem;
  height: 1.2rem;
  font-size: 1rem;
  color: var(--color-base-black);
  background-color: var(--color-base-white);
  font-family: var(--font-family-montserrat);
}
